/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React, {useEffect} from 'react'
 import PropTypes from 'prop-types'
 import { useStaticQuery, graphql } from 'gatsby'
 import Header from './Header'
 import Footer from './Footer'
 
 const Layout = ({ children }) => {
	 const data = useStaticQuery(graphql`
		 query SiteTitleQuery {
			 site {
				 siteMetadata {
					 title
				 }
			 }
		 }
	 `)
 
	 const listener = (event, iframe) => {
		 switch (event.data.type) {
			 case 'ANNOUNCEMENT_SHOW': {
				 iframe.classList.add(
					 'business-halland-widget--show-notification'
				 )
				 return
			 }
			 case 'ANNOUNCEMENT_HIDE': {
				 iframe.classList.remove(
					 'business-halland-widget--show-notification'
				 )
				 return
			 }
			 case 'WIDGET_OPEN': {
				 iframe.classList.add('business-halland-widget--open')
				 return
			 }
			 case 'WIDGET_CLOSE': {
				 iframe.classList.remove('business-halland-widget--open')
				 return
			 }
 
			 default: {
				 return
			 }
		 }	
	 }
 
	 useEffect(() => {
		 const iframe = document.getElementById('business-halland-widget');
 
		 if (iframe) {
			 window.addEventListener('message', e => listener(e, iframe), false);
		 }
	 }, [])
 
	 return (
		 <div className="font-sans relative overflow-hidden">
			 <Header siteTitle={data.site.siteMetadata.title} />
			 <main className="min-h-screenWithoutNavAndFooter w-full overflow-x-hidden">{children}</main>
			 <iframe title="business-halland-widget" id="business-halland-widget" src="https://widget.businesshalland.se/" allowtransparency="true" frameBorder="none"></iframe>
			 <Footer siteTitle={data.site.siteMetadata.title} />
		 </div>
	 )
 }
 
 Layout.propTypes = {
	 children: PropTypes.node.isRequired,
 }
 
 export default Layout
 