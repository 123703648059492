import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Footer = ({ siteTitle }) => {
	const data = useStaticQuery(graphql`
		query AllFooterQuery {
			wordpressAcfOptions {
				options {
					footer_logotypes {
						link
						image {
							id
							localFile {
								childImageSharp {
									fluid(quality: 100) {
										...GatsbyImageSharpFluid_withWebp
									}
								}
							}
						}
					}
					visibility
				}
			}
		}
	`)

	const images = data.wordpressAcfOptions?.options?.footer_logotypes
	const visibility = data.wordpressAcfOptions?.options?.visibility

	return (
		<>
			{/* {visibility && (
				<div className={
					"mx-auto block px-3 py-10 " +
					"max-w-screen-2xl md:px-6 lg:px-10 xl:px-20"}>
						<div className="w-full font-bold text-2xl md:text-2xl">
							Nyfiken på fler evenemang?
						</div>
						<div className="grid grid-cols-3 md:grid-cols-6 gap-4 w-full">
							{ images && images.map((el) => (
								<div key={el.id}>
									<a href={el.link} target="_blank">
										<Img
											className="w-full h-full"
											imgStyle={{
												objectFit: "contain",
												objectPosition: "50% 50%",
											}}
											fluid={el.image?.localFile?.childImageSharp?.fluid}
										/>
									</a>
								</div>
							))}
						</div>
				</div>
			)} */}
			<footer className="bg-black">
				<div className={
					"mx-auto block md:flex flex-column justify-between px-3 py-10 " +
					"max-w-screen-2xl md:px-6 lg:px-10 xl:px-20"}>
					<div className={
						"w-full items-center mb-10 border-l-4 h-fit " +
						"border-green-accent pl-4 md:mb-0 md:w-1/3"}>
						<Link
							to="/"
							className="font-bold text-2xl md:text-2xl mb-2 block text-white"
						>
							{siteTitle}
						</Link>
						<p className="md:w-3/4 w-full leading-tight text-base text-white">
							Ta nästa steg i ditt företag
						</p>
					</div>
					<nav className="w-full md:w-1/2 lg:w-1/3 pl-5 md:flex md:items-center md:justify-between">
						<div>
							<span className="font-bold text-lg md:mb-2 block pb-1 text-white">
								SIDOR
							</span>
							<Link to="/" className="md:mb-1 block text-lg text-white">
								Hem
							</Link>
							<Link to="/evenemang" className="md:mb-1 block text-lg text-white">
								Evenemang
							</Link>
						</div>
						<div className='pt-8 md:pt-0'>
							<span className="font-bold text-lg md:mb-2 block pb-1 text-white">
								OM WEBBPLATS
							</span>
							<Link to="/kontakt" className="md:mb-1 block text-lg text-white">
								Om oss
							</Link>
							<a href="mailto:Jenny.Blomedal@regionhalland.se" className="md:mb-1 block text-lg text-white">
								Kontakt
							</a>
						</div>
					</nav>
				</div>
			</footer>
		</>
	)
}

Footer.propTypes = {
	siteTitle: PropTypes.string,
}

Footer.defaultProps = {
	siteTitle: ``,
}

export default Footer
