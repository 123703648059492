import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { FiMenu, FiX } from 'react-icons/fi'

const Header = ({ siteTitle }) => {
	const [active, setActive] = useState(false)

	const openMenu = () => {
		setActive(true)
	}

	const closeMenu = () => {
		setActive(false)
	}

	return (
		<header className="bg-black z-50">
			<div className='max-w-screen-2xl md:px-6 lg:px-10 xl:px-20 flex justify-between items-center py-2 mx-auto'>
				<div className="flex flex-col items-center pl-4 md:p-0">
					<Link
						to="/"
						className={
							"flex items-center px-2 h-16 leading-none font-bold " +
							"text-lg md:text-2xl md:font-normal md:p-0 text-white"}
					>
						Business
						<span className="text-green-accent md:font-semibold">Halland</span>
					</Link>
				</div>
				<div className="flex items-center relative md:w-1/2">
					<div className="pr-4 md:hidden">
						{active === false ? (
							<FiMenu
								className="w-8 h-6 text-white"
								onClick={openMenu}
							/>
						) : (
							<FiX
								className="w-8 h-6 text-white"
								onClick={closeMenu}
							/>
						)}
					</div>
					<div
						className={
							`${
								active === true ? 'h-60vh' : 'h-0 transition-all'
							} duration-300 overflow-hidden bg-black absolute right-0 ` +
							' top-50 flex flex-col z-50 w-screen justify-between transition-all ' +
							' md:h-auto md:flex-row md:top-0 md:relative md:w-full md:justify-end'
						}
					>
						<div className={
							'ml-6 flex flex-col justify-center h-3/5 items-start ' +
							'pb-5 md:flex-row md:h-auto md:p-0 md:m-0 md:items-center'}>
							<div className={
								"text-green-accent border-b border-green-accent text-xs " +
								"font-semibold w-fit mb-3 md:hidden"}>
								LÄNKAR
							</div>
							<StyledLink
								to="/guider"
								className={
									"overflow-hidden mb-3 relative inline-flex items-center text-white " +
									"text-xl md:text-base md:mr-5 lg:mr-8 md:mb-0 hover:text-gray-700"}
								activeClassName="active"
							>
								Guider
							</StyledLink>
							<StyledLink
								to="/information"
								className={
									"overflow-hidden mb-3 relative inline-flex items-center text-white " +
									"text-xl md:text-base md:mr-5 lg:mr-8 md:mb-0 hover:text-gray-700"}
								activeClassName="active"
							>
								
								Information
							</StyledLink>
							<StyledLink
								to="/erbjudanden"
								className={
									"overflow-hidden mb-3 relative inline-flex items-center text-white " +
									"text-xl md:text-base md:mr-5 lg:mr-8 md:mb-0 hover:text-gray-700"}
								activeClassName="active"
							>
								
								Erbjudanden
							</StyledLink>
							<StyledLink
								to="/natverk"
								className={
									"overflow-hidden mb-3 relative inline-flex items-center text-white " +
									"text-xl md:text-base md:mr-5 lg:mr-8 md:mb-0 hover:text-gray-700"}
								activeClassName="active"
							>
								
								Nätverk
							</StyledLink>
							<StyledLink
								to="/evenemang"
								className={
									"overflow-hidden relative mr-6 inline-flex items-center text-white " +
									"text-xl md:text-base md:m-0 hover:text-gray-700"}
								activeClassName="active"
							>
								Evenemang
							</StyledLink>
						</div>
						<div className={
							'bg-white border-b-8 border-green-accent w-full text-white ' +
							'h-2/5 flex flex-col justify-evenly py-8 md:hidden '}>
							<div className={
								"ml-6 text-green-accent border-b border-green-accent text-xs " +
								"font-semibold w-fit"}>
								KONTAKT
							</div>
							<a
								href="mailto:Jenny.Blomedal@regionhalland.se"
								className="underline pl-6 text-lg text-black"
							>
								Jenny.Blomedal@regionhalland.se
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

const StyledLink = styled(Link)`

	&.active {
		color: #5A9D64;
	}
`

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
